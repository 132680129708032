.page-grid-items .grid-item {
  border: 1px solid #d9e1eb;
  padding: 24px;
  border-radius: 16px;
  cursor: pointer;
  margin-bottom: 16px;
  transition: var(--transition);
  user-select: none;
}
.page-grid-items .grid-item:hover {
  border-color: var(--primary-button);
}
.grid-item .grid-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}
.grid-item .grid-item-header .grid-icon {
  height: 56px;
  width: 56px;
  display: flex;
  border-radius: 50%;
}
.grid-item .grid-item-header .grid-icon img {
  height: 25px;
  margin: auto;
}

.grid-item-content h2 {
  color: #171717;
  font-size: 22px;
  font-weight: 500;
}
.grid-item-content .grid-sub-text {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
}
.complete-badge {
  display: flex;
  align-items: center;
}
.complete-badge span {
  font-size: 16px;
  color: #8b8b8b;
  margin-left: 10px;
}
.complete-badge img {
  height: 20px;
}

.page-grid-items--box .grid-item-content .complete-badge {
  margin-top: 26px;
}

.common-progress-bar {
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-grid-items--box .common-progress-bar {
  margin-top: 18px;
}

@media screen and (min-width: 1199px) {
  .page-grid-items--box .common-progress-bar {
    margin-bottom: 10px;
  }
}
.common-progress-bar .progress-track {
  height: 5px;
  position: relative;
  background-color: #f2f2f2;
  width: calc(100% - 10px);
  border-radius: 16px;
  overflow: hidden;
}
.common-progress-bar .progress-highlight {
  background-color: #5fbf84;
  height: 100%;
  position: relative;
  width: 0;
  border-radius: 16px;
}

.common-progress-bar .failed {
  background-color: #ff9900;
}
.common-progress-bar .progress-label-txt {
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 500;
}

.grid-item .grid-badge {
  background-color: #fff6e2;
  font-size: 12px;
  font-weight: 400;
  color: #dca21b;
  padding: 4px 7px;
  border-radius: 4px;
}

.grid-item .grid-badge img {
  margin-right: 4px;
}

.mini-grid-item .grid-item-content h2 {
  font-size: 20px;
  font-weight: 600;
}

.grid-item.mini-grid-item[aria-disabled="true"],
grid-item.mini-grid-item[aria-disabled="true"]:hover {
  opacity: 0.5 !important;
  user-select: none;
  cursor: not-allowed !important;
  border-color: #d9e1eb;
}

.grid-item-content .item-index {
  flex: none;
  background-color: #c1e8fb;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.grid-item-content .text-contents {
  word-break: break-all;
}

.page-grid-items.page-grid-items--row .grid-item.mini-grid-item {
  overflow: hidden;
}
.page-grid-items.page-grid-items--row .grid-item-content {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .page-grid-items.page-grid-items--row .grid-right-block {
    margin-left: 65px;
    margin-top: 2px;
  }
}
@media screen and (min-width: 768px) {
  .page-grid-items.page-grid-items--row .grid-item-content {
    gap: 20px;
  }
}
.page-grid-items.page-grid-items--row .grid-left-block,
.page-grid-items.page-grid-items--row .grid-right-block {
  display: flex;
  gap: 18px;
  align-items: center;
}
.page-grid-items.page-grid-items--row .grid-left-block {
  justify-content: flex-start;
}

.page-grid-items.page-grid-items--row .common-progress-bar {
  width: 140px;
}
.page-grid-items.page-grid-items--row .right-arrow-element {
  margin-left: 26px;
  transform: translateX(0);
  transition: var(--transition);
}

.page-grid-items.page-grid-items--row .grid-item:hover .right-arrow-element {
  transform: translateX(4px);
}

.page-grid-items.page-grid-items--row .right-arrow-element img {
  height: 18px;
}

.page-grid-items.page-grid-items--row .common-progress-bar .progress-label-txt {
  font-size: 16px;
}

@media screen and (min-width: 767px) {
  .page-grid-items.page-grid-items--box {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
  }
  .page-grid-items.page-grid-items--box .grid-item {
    width: calc(50% - 11px);
    margin-bottom: 0;
  }
  .page-bottom-logos {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb-element,
  .page-header-right .logo-group {
    display: none;
  }
  .page-bottom-logos .logo-group {
    justify-content: center;
  }
  .page-bottom-logos {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media screen and (width: 767px) {
  .page-bottom-logos {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .page-grid-items.page-grid-items--box .grid-item {
    width: calc(33.3333% - 11px);
  }
}

.floating-icon {
  position: fixed;
  right: 30px;
  bottom: 80px;
  height: 48px;
  width: 48px;
  z-index: 220;
  cursor: pointer;
}
.floating-icon img {
  height: 48px;
  width: 48px;
}

@media screen and (max-width: 575px) {
  .floating-icon {
    bottom: 40px;
    height: 40px;
    width: 40px;
  }
  .floating-icon img {
    height: 40px;
    width: 40px;
  }
}

.grid-sub-date p {
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
