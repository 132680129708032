.review-page-header h3 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.review-header-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.review-header-actions .btn {
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 400;
}
.review-items-list {
  margin-top: 30px;
}
.review-items-list .review-item-ul {
  padding: 0;
  margin: 0;
}
.review-item-ul .review-item {
  background-color: #fff;
  padding: 14px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid #d9e1eb;
  list-style: none;
}

.review-item-ul .review-item p {
  font-size: 14px;
  margin-bottom: 0;
  color: #000000;
}
.review-idx-col {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c1e8fb;
  border-radius: 50px;
  flex: none;
  margin-right: 20px;
}
.review-content-col {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.review-action-col {
  margin-left: 54px;
  margin-top: 10px;
}

.review-ite-nav-link {
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline: none;
  background: none;
}
.review-ite-nav-link svg {
  margin-left: 10px;
}
.review-ite-nav-link:disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}
.review-ite-nav-link:not(:disabled):hover {
  opacity: 0.8;
  transition: 0.2s ease;
}

.review-ite-nav-link.review-not-answered {
  color: #666666 !important;
}
.review-ite-nav-link.completed {
  color: #129610 !important;
}
.review-ite-nav-link.mark-for-review {
  color: #dcae34 !important;
}

@media screen and (min-width: 768px) {
  .review-item-ul .review-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .review-content-col {
    align-items: center;
    width: calc(100% - 180px);
  }
  .review-action-col {
    width: 180px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 0;
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .review-ite-nav-link svg {
    display: none !important;
  }
}

@media screen and (max-width: 380px) {
  .review-page-header h3 {
    font-size: 18px !important;
  }
  .review-header-actions .btn {
    padding: 5px 16px !important;
    font-size: 14px !important;
  }
}
