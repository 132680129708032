.inner-page-header {
  margin-bottom: 24px;
  gap: 14px;
}

@media screen and (min-width: 768px) {
  .inner-page-header {
    margin-bottom: 50px;
  }
}

.inner-page-header .page-title {
  color: #171717;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0;
  word-break: break-all;
}

.page-grid-items.grid-item-certificate .grid-item {
  padding: 0;
  overflow: hidden;
}
.page-grid-items.grid-item-certificate .grid-item .grid-item-content {
  display: flex;
  justify-content: start;
}
.page-grid-items.grid-item-certificate .grid-item .grid-item-left {
  background-color: #e9f1f8;
  width: 98px;
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
}

.page-grid-items.grid-item-certificate .grid-item .grid-item-left img {
  height: 48px;
}

.page-grid-items.grid-item-certificate .grid-item .grid-item-right {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 15px;
  min-height: 130px;
}

.page-grid-items.grid-item-certificate .grid-item-badge {
  border: 1px solid #cfe4f6;
  font-size: 12px;
  font-weight: 500;
  color: #2760ad;
  padding: 4px 6px;
  display: inline-block;
  line-height: normal;
  border-radius: 4px;
  margin-bottom: 10px;
}

.page-grid-items.grid-item-certificate .grid-item .grid-item-right h2 {
  color: #171717;
  font-size: 14px;
  font-weight: 500;
}
.page-grid-items.grid-item-certificate .grid-item .grid-item-footer {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}
.page-grid-items.grid-item-certificate .grid-item .grid-footer-item {
  font-size: 12px;
  font-weight: 400;
  color: #171717;
  display: flex;
  align-items: center;
  gap: 5px;
}

.page-grid-items.grid-item-certificate .grid-item .grid-footer-item img {
  height: 14px;
}

.select-dropdown-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

.custom-select-drop {
  appearance: none;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 26px 8px 12px;
  color: #131314;
  font-size: 14px;
  font-weight: 500;
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-image: url("../../../assets/images/icons/down-arrow-black.svg");
  outline: none;
  transition: var(--transition);
}
.custom-select-drop:focus {
  border-color: #c6daef !important;
  box-shadow: 0 0 0 4px rgb(143 189 232 / 25%);
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 800;
}

.preview-modal .preview-header {
  background-color: #fff;
}

.preview-modal .preview-header h2 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
}
.preview-modal .preview-header .preview-head {
  display: flex;
  align-items: center;
  gap: 12px;
}
.preview-modal .preview-header .preview-back-btn {
  border: 0;
  background: transparent;
  width: 36px;
  height: 32px;
}

.preview-modal .preview-header .preview-back-btn img {
  height: 26px;
}

.preview-modal .preview-header-block {
  height: 70px;
  padding: 8px 0;
}

.preview-modal .preview-content {
  position: relative;
  height: calc(100dvh - 70px);
  width: 100%;
}

.preview-modal .preview-content .preview-overlay {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.preview-modal .preview-content .preview-box {
  position: absolute;
  height: 100%;
  z-index: 15;
  width: 100%;
  display: flex;
}

.preview-modal .preview-content .preview-box img {
  max-height: 100%;
  margin: auto;
  max-width: 100%;
  padding: 10px;
}
.preview-modal .preview-actions {
  display: flex;
  gap: 14px;
  align-items: center;
}
.preview-modal .preview-actions .btn {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 44px;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.preview-modal .preview-actions .btn.btn-secondary {
  border: 1px solid #cccccc;
  background-color: #fff;
  color: #131314;
}
.preview-modal .preview-actions .btn.btn-primary {
  border: 1px solid #324f96;
  background-color: #324f96;
  color: #fff;
}
.preview-modal .preview-actions .btn img {
  height: 20px;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
}

.react-pdf__Page__canvas {
  max-height: 80dvh !important;
  max-width: 90% !important;
  width: auto !important;
  height: auto !important;
}

@media screen and (max-width: 767px) {
  .preview-modal .certificate-download-btn > span {
    display: none;
  }
  .preview-modal .preview-header h2 {
    max-width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
