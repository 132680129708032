.media-preview-modal .media-preview-img {
  max-width: 100%;
  vertical-align: middle;
}

.media-preview-modal .media-preview-video {
  line-height: 0 !important;
}

.media-preview-modal .media-content-holder {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.media-preview-modal .media-modal-close {
  width: 32px;
  height: 32px;
  border: 0;
  outline: 0;
  background-color: #000;
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 50%;
  z-index: 20;
  transition: 0.2s ease;
}

.media-preview-modal .media-modal-close:hover {
  background-color: #1b1b1b;
}
.media-preview-modal .media-modal-close svg {
  width: 28px;
  height: 28px;
}

@media screen and (min-width: 992px) {
  .media-preview-modal .media-modal-close {
    width: 44px;
    height: 44px;
  }
}
