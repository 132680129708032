.error-page-content {
  height: calc(100dvh - 220px);
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-page-content .error-content-block {
  text-align: center;
  max-width: 375px;
  padding: 14px;
}
.error-page-content .error-content-block img {
  height: 265px;
  margin-bottom: 24px;
}
.error-page-content .error-content-block .error-title {
  color: #171717;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 14px;
}

.error-page-content .error-content-block .error-description {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}
.error-page-content .error-content-block .error-page-action {
  padding-top: 10px;
}
.error-page-content .error-content-block .error-page-action .btn {
  padding: 10px 20px;
  border: 0;
  outline: 0;
}

@media screen and (max-width: 767px) {
  .error-page-content {
    height: calc(100dvh - 150px);
  }
}

@media screen and (max-width: 575px) {
  .error-page-content {
    height: calc(100dvh - 130px);
  }
  .error-page-content .error-content-block img {
    height: 200px;
  }
}
