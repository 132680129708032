.header-dropdown {
  position: absolute;
  top: calc(100% + 26px);
  right: 0;
  z-index: 100;
}

.header-card {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.header-dropdown.header-user-card {
  width: 316px;
  overflow: hidden;
}

.header-dropdown.header-user-card .user-basic-info {
  gap: 15px;
  border-bottom: 1px solid #f2f2f2;
}
.header-dropdown.header-user-card .user-basic-info .user-avatar {
  display: flex;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: none;
  color: #fff;
  background-color: #1ba05a;
  font-weight: 600;
  font-size: 24px;
}
.header-dropdown.header-user-card .user-info-text .user-name-txt {
  color: #131314;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
}

.header-dropdown.header-user-card .user-info-text .user-sub-txt {
  color: #656566;
  font-size: 14px;
  font-weight: 400;
}
.header-dropdown.header-user-card .user-info-text .user-sub-txt.user-email {
  word-break: break-all;
  margin-top: 2px;
}
.header-dropdown.header-user-card .actions-list .action-btn {
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 16px;
  color: #131314;
  font-size: 12px;
  font-weight: 500;
  transition: var(--transition);
}
.header-dropdown.header-user-card .action-btn .action-btn-content {
  display: flex;
  justify-content: center;
}
.header-dropdown.header-user-card .action-btn .action-btn-content img {
  height: 16px;
  margin-right: 8px;
}
.header-dropdown.header-user-card .actions-list .action-btn .btn-arrow {
  transform: translateX(0);
  transition: var(--transition);
}
.header-dropdown.header-user-card .actions-list .action-btn:hover {
  background-color: #f8f8f8;
}
.header-dropdown.header-user-card .actions-list .action-btn:hover .btn-arrow {
  transform: translateX(3px);
}
.header-dropdown.header-user-card .actions-list .action-btn .btn-arrow img {
  height: 14px;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: dimmer 1s linear infinite;
}

.skeleton-text {
  width: 100%;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: dimmer 1s linear infinite;
}

.skeleton-div {
  width: 100%;
  height: 50px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: dimmer 1s linear infinite;
}

.skeleton-small {
  width: 60px;
}

@keyframes dimmer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.cutome-width-for-username {
  width: calc(100% - 60px);
}

.user-avatar.skeleton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0 !important;
  animation: dimmer 1s linear infinite;
}

.skeleton-icon {
  width: 24px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.action-btn.skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 8px;
}

.action-btn-content .skeleton-text {
  width: 80px;
  height: 14px;
}
