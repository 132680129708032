.help-page-search-wrapper {
  margin-bottom: 40px;
  margin-top: 25px;
}
.help-page-search-wrapper h3 {
  font-size: 24px;
  font-weight: 700;
  color: #131314;
  margin-bottom: 0;
}
.help-page-search-wrapper .search-box input[type="text"] {
  border: 1px solid #e6e6e6;
  padding: 13px 16px;
  border-radius: 6px;
  color: #656566;
  font-size: 16px;
  font-weight: 400;
  display: block;
  width: 100%;
  outline: 0;
}
.help-page-search-wrapper .search-box input[type="text"]:focus {
  border-color: #c6daef !important;
  box-shadow: 0 0 0 4px rgb(143 189 232 / 25%);
}
.help-side-nav {
  list-style-type: none;
  padding-left: 0;
}

.help-side-nav li {
  margin-bottom: 20px;
  cursor: pointer;
  word-break: break-all;
  text-decoration: underline;
}
.help-side-nav li a {
  display: block;
  text-decoration: none;
  color: #656566;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  transition: var(--transition);
}
.help-side-nav li:hover {
  color: #000;
}
.help-side-nav li.active {
  color: #000;
  font-weight: 600;
}
