.change_password .form-label {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: #171717;
}

.common-modal-content .modal-icon-small {
  height: 40px !important;
  margin-bottom: 10px !important;
}

.common-modal-content .modal-icon-big {
  height: 165px !important;
  margin-bottom: 10px !important;
}

.modal-action .btn_password {
  padding: 10px !important;
}

.common-modal-content .modal-action .btn-primary {
  min-width: 118px;
  border: 0;
}

.modal-action .button-group .btn.btn-secondary {
  color: #002752;
  background-color: #f0f0f0;
  width: 89px;
}
.modal-action .button-group .btn.btn-secondary,
.modal-action .button-group .btn.btn-primary {
  border: 0;
  outline: 0;
  font-weight: 600;
  font-size: 14px;
}

.modal-action .button-group .btn.btn-primary {
  min-width: 170px;
}

.change_password .form-control {
  padding-right: 40px !important;
}
