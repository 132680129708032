@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-pdfviewer/styles/material.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-button: #2760ad;
  --primary-button-hover: #256bc7;
  --green-button: #00ab65;
  --green-button-hover: #04c172;
  --primary-button-hover-light: #e8f2ff;
  --transition: 0.3s ease;
}

body {
  font-family: "Roboto", sans-serif !important;
  background-color: #fff;
}
select,
input,
button {
  font-family: "Roboto", sans-serif !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

a {
  cursor: pointer;
}

a[data-disabled="true"] {
  opacity: 0.6;
  pointer-events: none !important;
}

.btn-nergy-primary {
  background-color: var(--primary-button) !important;
  border: 0 !important;
  color: #fff !important;
  padding: 10px !important;
  min-width: 112px !important;
}

.error-message-text {
  font-size: 12px;
  font-weight: 400;
  margin-top: 3px;
}

select.form-select.invalid {
  border-color: #dc3545 !important;
}

select.form-select.invalid:focus {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

select.form-select {
  min-height: 44px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.form-group[class*="has-icon-right"] .passwordTglBtn {
  position: absolute;
  right: 12px;
  top: 35px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  font-size: 22px;
  color: #888;
}
.form-group[class*="has-icon-right"] .passwordTglBtn i {
  line-height: 1;
}

/* CUSTOME SCROLLBAR */

::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Firefox */
* {
  scrollbar-width: thin; /* "thin" makes it a thin scrollbar */
}

/* IE and Edge */
* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Adjust the color as needed */
}

/* width */
.common-modal-content::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.common-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.common-modal-content::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
.common-modal-content::-webkit-scrollbar-thumb:hover {
  background: #ced2e4;
}

.window-height-100 {
  height: 100dvh;
}

@media screen and (max-width: 575px) {
  .window-height-mobile {
    min-height: calc(100dvh - 52px);
  }
  .auth-form-footer {
    margin-top: 15px !important;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 576px) {
  .window-height-md-100 {
    height: calc(100dvh - 82px);
  }
}

.custom-checkbox {
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  color: #171717;
  user-select: none;
}
.custom-checkbox span {
  margin-left: 10px;
}
.form-text-link {
  color: #2760ad;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.auth-header {
  width: 100%;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #e6e6e6;
  z-index: 200;
}

.header-block {
  height: 74px;
}

.auth-header .brand-logo {
  display: inline-block;
}
.auth-header .brand-logo img {
  height: 40px;
}

.auth-header .header-actions {
  gap: 26px;
  position: relative;
}
.auth-header .header-actions .header-action-btn {
  cursor: pointer;
  display: block;
  padding: 5px;
}

.auth-header .header-actions img {
  height: 24px;
}

.auth-graphics-block {
  background-color: #f3f8ff;
  position: relative;
}

.auth-graphics-block .graphic-block {
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.auth-graphics-block .figure-bg {
  background-color: #ccdaee;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30dvh;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}

.auth-graphics-block .graphic-block img {
  width: 100%;
  max-width: 450px;
  vertical-align: middle;
}

@media screen and (min-width: 576px) {
  .auth-graphics-block .graphic-block {
    padding-right: 50px;
  }
  .auth-form {
    padding-bottom: 55px;
  }
  .auth-form-footer {
    margin-top: -44px;
  }
}
@media screen and (max-width: 575px) {
  .auth-graphics-block .figure-bg {
    display: none !important;
  }

  .auth-graphics-block .graphic-block img {
    max-width: 70%;
    margin: auto;
    display: block;
  }
  .auth-form-footer {
    margin-top: 120px;
  }
  .header-block {
    height: 50px;
  }
  .auth-header .brand-logo img {
    height: 32px;
  }
}

.auth-form {
  max-width: 500px;
}
.form-scroll {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding-left: 5px;
}

@media screen and (max-width: 767px) {
  .auth-form .form-block {
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .auth-form {
    padding-left: 50px;
  }
}

@media screen and (min-width: 990px) {
  .auth-form {
    padding-left: 100px;
  }
}

/* AUTH style */
.auth-form .form-block {
  max-width: 352px;
  width: 95%;
}

.auth-form .form-block h2 {
  color: #333333;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.auth-form .form-block .form-label {
  font-size: 12px;
  font-weight: 400;
  color: #171717;
}

.auth-form .form-block input {
  padding: 10px 16px;
}

.auth-form .form-block textarea {
  padding: 12px 16px;
  min-height: 100px;
}

.auth-form .form-block .invalid-feedback {
  color: #dc4040;
  font-size: 12px;
  font-weight: 400;
}

.button-group {
  gap: 10px;
}
.auth-form .form-block .btn.btn-primary {
  background-color: var(--primary-button);
  display: block;
  width: 100%;
  padding: 10px 16px;
}

.auth-form .form-block .btn.btn-secondary {
  border: #e6e6e6 solid 1px;
  background-color: #ffffff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 16px;
  width: 100%;
  display: block;
}

.auth-form-user-info img {
  width: 24px;
  height: 24px;
}
.auth-form-user-info span {
  color: #002752;
  font-size: 14px;
  font-weight: 400;
}
.form-block .form-auth-sm-txt {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.auth-form-footer {
  width: 100%;
  padding: 0 14px;
}

.footer-nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer-nav li {
  list-style-type: none;
}

.footer-nav li:not(:first-child) {
  background-position: left 10px center;
  padding-left: 26px;
  background-image: url("./assets/images/grey-dot.svg");
  background-size: 5px;
  background-repeat: no-repeat;
}

.footer-nav a {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
}

.app-version {
  color: #656566;
  font-size: 12px;
  font-weight: 400;
}
.main-footer .app-version {
  display: none;
}
.copyright-text {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  text-transform: lowercase;
}
.invitation-content {
  text-align: center;
}
.invitation-content .block-imgage {
  height: 80px;
  margin-bottom: 30px;
}
.invitation-content h2 {
  font-size: 22px !important;
  font-weight: 500;
  color: #171717;
}

.invitation-text .form-auth-sm-txt span {
  color: #171717;
  font-weight: 500;
}

.invite-link {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #e6e6e6;
  user-select: none;
}
.delete-confirm-text {
  margin-top: 20x;
  font-size: 18px !important;
  text-align: center;
  color: #666666;
}
.common-modal-content {
  position: relative;
  background-color: #fff;
  margin: auto;
  border-radius: 8px;
  padding: 32px 40px !important;
  text-align: center;
  width: 95%;
}

.common-modal .ql-editor img {
  max-width: 100% !important;
}
.common-modal.modal__sm .common-modal-content {
  max-width: 400px;
}

.common-modal.modal__register_id .common-modal-content {
  max-width: 328px !important;
  padding: 30px 22px !important;
}

.common-modal.modal__terms .common-modal-content {
  max-width: 768px;
  padding: 30px;
}

.common-modal-content .modal-icon {
  height: 80px;
  margin-bottom: 22px;
}

.common-modal.modal__register_id .modal-icon {
  height: 120px !important;
  margin-bottom: 18px !important;
}
.common-modal-content h2 {
  color: #333333;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 22px;
}
.common-modal.modal__terms .common-modal-content h2,
.common-modal.modal__terms .common-modal-content p {
  text-align: left;
}
.common-modal:not(.program-terms-modal) .common-modal-content p {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
}
.common-modal.activation_code.modal-dialog .modal-content {
  background-color: transparent !important;
  border: none !important;
}
.modal__terms .common-modal-content p {
  max-width: 100%;
  margin-bottom: 16px;
  color: #494949;
  font-size: 16px;
}
.common-modal-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
}
.common-modal-header h2 {
  margin-bottom: 0;
}
.modal-input-field {
  margin-top: 24px;
}
.modal-input-field .form-label {
  font-size: 12px;
  font-weight: 400;
  color: #324f96;
  display: block;
  text-align: left;
}

.common-modal-content .modal-action {
  margin-top: 24px;
}
.common-modal-content .form-control {
  padding: 10px 14px;
}
.common-modal.modal__register_id .common-modal-content h2 {
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 700;
}
.modal-action .btn {
  padding: 10px;
}

.common-modal-content .modal-action .btn-primary {
  background-color: var(--primary-button);
  min-width: 118px;
  border: 0;
}

.modal-action .button-group .btn.btn-secondary {
  color: #002752;
  background-color: #f0f0f0;
  width: 89px;
}
.modal-action .button-group .btn.btn-secondary,
.modal-action .button-group .btn.btn-primary {
  border: 0;
  outline: 0;
  font-weight: 600;
  font-size: 14px;
}

.modal-action .button-group .btn.btn-primary {
  min-width: calc(100% - 89px);
}
.common-modal.modal__register_id .modal-action .btn.btn-primary {
  padding: 10px;
}

.modal-close-btn {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: -46px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/images/icons/close-icon-white.svg");
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
}

.modal-footer-content {
  margin-top: 20px;
  text-align: center;
}

.modal-footer-content p {
  color: #171717;
  font-weight: 14px;
  font-weight: 500;
}

.modal-footer-content p a {
  color: var(--primary-button);
  text-decoration: none;
}

.common-modal .modal-tab {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  user-select: none;
}

.common-modal .modal-tab.active {
  border-bottom: 4px solid #324f96;
}

.common-modal-content .main-heading {
  font-size: 32px;
  font-weight: 500;
  margin-top: 10px;
}

.common-modal-content .main-content-text {
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
  font-weight: 400;
}

.main-page-wrapper {
  padding-top: 30px;
}

.banner-slide {
  background-color: #eaf2ff;
  border-radius: 16px;
  overflow: hidden;
  padding: 24px 32px;
  height: 234px;
}
.banner-slide .banner-content-text {
  max-width: 340px;
  position: relative;
  z-index: 20;
}
.banner-slide .banner-content-text h2 {
  font-size: 24px;
  font-weight: 600;
  color: #171717;
  margin-bottom: 14px;
}

.banner-slide .banner-content-text p {
  font-size: 18px;
  font-weight: 400;
  color: #171717;
  margin: 0;
}
.banner-slide .banner-graphic-rect {
  background-color: #d9e6fb;
  width: 474px;
  height: 514px;
  border-radius: 40px;
  transform: rotate(45deg);
}

.banner-slide .banner-graphics {
  position: relative;
}

.banner-slide .banner-graphic-rect {
  background-color: #d9e6fb;
  width: 790px;
  height: 640px;
  border-radius: 40px;
  transform: rotate(63deg);
  position: absolute;
  top: -75%;
  left: 0;
  z-index: 1;
}

.banner-slide .banner-graphics img {
  position: relative;
  z-index: 5;
  height: 234px;
}

@media screen and (max-width: 600px) {
  .banner-slide .banner-content-text p {
    font-size: 12px;
  }
  .banner-slide .banner-content-text h2 {
    font-size: 18px;
  }
}

/* slick slider dot */

ul.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0;
  list-style: none;
  margin-top: 8px;
  gap: 8px;
  margin-bottom: 0;
}

ul.slick-dots button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  font-size: 0;
  background-color: #cccccc;
  border: 0;
  outline: 0;
}

.banner-slider {
  margin-bottom: 40px;
}
.featured-section {
  margin-bottom: 50px;
}
.featured-section-header {
  margin-bottom: 25px;
}
.featured-list {
  gap: 15px;
}
.featured-list .featured-item {
  border: 1px solid #d9e1eb;
  border-radius: 16px;
  overflow: hidden;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .featured-list .featured-item {
    width: calc(50% - 15px);
    margin-bottom: 0;
  }
}

@media screen and (min-width: 992px) {
  .featured-list .featured-item {
    width: calc(33.333% - 15px);
  }
}

.featured-section-header h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  color: #171717;
}
.featured-item .item-thumbnail {
  height: 200px;
  width: 100%;
  border-radius: 12px 12px 0 0;
  text-align: center;
  margin-bottom: 16px;
}
.featured-item .item-thumbnail img {
  height: 200px;
  object-fit: cover;
}

.featured-item h3 {
  color: #171717;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.featured-item span.badge {
  color: #faa51a;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #faa51a;
  border-radius: 12px;
  display: inline-block;
}
.featured-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 4px;
}
.indicators-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.indicators-list .featured-content {
  padding: 0 10px;
}

.indicators-list .indicator {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  background-color: #eaeff7;
}

@media screen and (min-width: 768px) {
  .featured-content .featured-badge {
    min-height: 25px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1199px) {
  .indicators-list .indicator {
    height: 32px;
    width: 32px;
  }
}

.indicators-list .indicator.active {
  background-color: #5fbf84 !important;
}
.indicators-list .indicator svg {
  margin: auto;
  height: 16px;
  fill: #808080;
}
.indicators-list .indicator svg path {
  fill: #808080;
}
.indicators-list .indicator.active svg path {
  fill: #ffffff;
}
.featured-footer .btn.btn-primary {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background-color: var(--primary-button);
  border: 0;
  outline: 0;
  padding: 10px;
}

.featured-footer .btn .btn-arrow {
  margin-left: 6px;
  display: inline-block;
  transform: translateX(0);
  transition: 0.3s ease;
}
.featured-footer .btn:hover .btn-arrow {
  transform: translateX(4px);
}

.main-footer-block {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.main-footer-block .copyright-text {
  text-transform: uppercase;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.assessment-info-card .btn,
.assessment-modal-action-btns .btn,
.common-modal.modal__assessment_schedule .btn,
.question-sidebar-footer-action .btn,
.assessment-result-board .action-block .btn {
  min-width: 74px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 16px;
  outline: none;
  transition: var(--transition);
}

button.btn.btn-blue {
  background-color: var(--primary-button);
  border-color: var(--primary-button);
  color: #fff;
}
button.btn.btn-green {
  background-color: var(--green-button) !important;
  border-color: var(--green-button) !important;
  color: #fff;
}
button.btn.btn-blue-outline {
  border: var(--primary-button) solid 1px;
  color: var(--primary-button);
}
button.btn.btn-blue:hover,
button.btn.btn-blue:active {
  background-color: var(--primary-button-hover) !important;
  color: #fff;
}
button.btn.btn-green:hover,
button.btn.btn-green:active {
  background-color: var(--green-button-hover) !important;
  border-color: var(--green-button-hover) !important;
  color: #fff;
}
button.btn.btn-blue-outline:hover,
button.btn.btn-blue-outline:active {
  border-color: var(--primary-button-hover) !important;
  color: var(--primary-button-hover) !important;
  background-color: var(--primary-button-hover-light) !important;
}
#container_menu_download {
  display: none !important;
}
.no_program_parent {
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  background-color: #f8fbff;
}
