.header-card {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.header-dropdown {
  position: absolute;
  top: calc(100% + 26px);
  right: 0;
  z-index: 100;
}
.header-dropdown.header-notification {
  width: 380px;
}

.header-dropdown.header-notification .header-card-header,
.notification-list .notification-item:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.notification-item img {
  max-width: 100%;
}

.header-dropdown.header-notification .header-card-content {
  max-height: 430px;
  overflow-y: auto;
}

.header-notification .header-card-header h2 {
  font-size: 18px;
  font-weight: 500;
  color: #131314;
  margin-bottom: 0;
  line-height: normal;
}

.header-notification .header-card-header a,
.header-notification .header-card-header span {
  font-size: 14px;
  font-weight: 500;
  color: #656566;
  display: inline-block;
  text-decoration: none;
}

.header-notification .header-card-header span {
  cursor: pointer;
}

.header-notification .header-card-header span:hover {
  color: inherit;
  text-decoration: none;
}

.notification-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.notification-list .notification-item {
  background-color: #fff;
  transition: var(--transition);
}
.notification-listng-page .notification-item {
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
}
.notification-listng-page .notification-item:last-child {
  margin-bottom: 0;
}
.notification-listng-page .notification-item > a,
.notification-listng-page .notification-item > span {
  padding: 16px !important;
}
.notification-list .notification-item:hover {
  background-color: #f8f8f8;
}
.notification-list .notification-item > a,
.notification-list .notification-item > span {
  display: block;
  text-decoration: none;
  padding: 10px 16px;
}

.notification-list .notification-item h5 {
  color: #000;
  font-weight: 500;
  font-size: 16px;
}

.notification-list .notification-item p {
  color: #131314;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
}
.notification-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.notification-item-footer .notification-badges {
  display: flex;
  gap: 8px;
}
.notification-item-footer .notification-badge {
  background-color: #c1e8fb;
  padding: 2px 4px;
  border-radius: 6px;
  color: #2760ad;
  font-size: 12px;
  font-weight: 500;
  word-break: break-all;
}

.notification-item-footer .notifiation-date {
  color: #636363;
  font-size: 12px;
  font-weight: 400;
  flex: none;
}

.link-decoration {
  text-decoration: none;
}

@media screen and (max-width: 400px) {
  .header-dropdown.header-notification {
    width: 300px;
  }
  .auth-header .header-actions {
    gap: 20px;
  }
}
