/* TASK | SINGLE PAGE WITH SIDEBAR */

.single-page-content {
  /* height: calc(100dvh - 94px); */
  background-color: #f5f7fa;
  padding-top: 18px;
  padding-bottom: 15px;
}

.single-page-content .content-wrapper {
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  height: calc(100dvh - 120px);
  width: 100%;
  position: relative;
}
.single-page-content .content-wrapper .content-footer {
  position: absolute;
  z-index: 10;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 32px;
}
.single-page-content .content-wrapper .content-block {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: calc(100% - 85px);
  overflow-y: auto;
  padding: 10px 32px 15px;
}
.single-page-content .content-footer .btn.btn-primary {
  background-color: var(--primary-button);
  border: 0;
  padding: 12px 18px;
  outline: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.single-page-content .content-footer .footer-rounded-btn {
  border-radius: 40px;
}
.single-page-sidebar {
  height: calc(100dvh - 125px);
  overflow: hidden;
}

.single-page-content.mobile-task-view {
  height: calc(100dvh - 120px) !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.single-page-content.mobile-task-view .content-wrapper {
  height: calc(100dvh - 130px) !important;
}

@media screen and (max-width: 575px) {
  .single-page-content.mobile-task-view {
    padding-top: 15px !important;

    height: calc(100dvh - 95px) !important;
  }

  .single-page-content.mobile-task-view .content-wrapper {
    height: calc(100dvh - 125px) !important;
  }
}

@media screen and (min-width: 576px) {
  /* .single-page-content {
      height: calc(100dvh - 118px);
    } */
  .single-page-sidebar {
    height: calc(100dvh - 150px);
  }
  .single-page-content .content-wrapper {
    height: calc(100dvh - 150px);
  }
}
@media screen and (min-width: 768px) {
  .single-page-content {
    height: calc(100dvh - 181px);
  }
  .single-page-sidebar {
    height: calc(100dvh - 215px);
  }
  .single-page-content .content-wrapper {
    height: calc(100dvh - 215px);
  }
}

.single-page-sidebar .sidebar-header {
  margin-top: 18px;
}
.single-page-sidebar .sidebar-header h3 {
  color: #131314;
  font-size: 20px;
  font-weight: 600;
}
.single-page-sidebar .sidebar-content {
  height: calc(100% - 154px);
  width: 100%;
  overflow-y: auto;
  margin-top: 18px;
}
.single-page-sidebar .sidebar-header .sidebar-sub-heading {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
}
.single-page-sidebar .sidebar-item-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  padding-right: 8px;
  padding-bottom: 20px;
}
.single-page-sidebar .sidebar-item:not(:last-child) {
  margin-bottom: 15px;
}
.single-page-sidebar .sidebar-item a {
  border: 1px solid #e6e6e6;
  display: flex;
  border-radius: 16px;
  width: 100%;
  align-items: center;
  text-decoration: none;
  padding: 8px 12px;
  background-color: #fff;
  transition: var(--transition);
  cursor: pointer;
  user-select: none;
}
.single-page-sidebar .sidebar-item .index-span {
  width: 30px;
  height: 30px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #171717;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
}
.single-page-sidebar .sidebar-item .item-content {
  margin-left: 15px;
}
.single-page-sidebar .sidebar-item .item-content .md-text {
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #171717;
}
.single-page-sidebar .sidebar-item .item-content .sm-text {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.single-page-sidebar .sidebar-item.completed a {
  background-color: #f3fdf6 !important;
  border-color: #bff0d2 !important;
}
.single-page-sidebar .sidebar-item.completed .index-span {
  background-color: #5fbf84 !important;
  border-color: #5fbf84 !important;
  color: #fff !important;
}

.single-page-sidebar .sidebar-item.current a {
  background-color: #f2f7fc !important;
  border-color: #9ebfe0 !important;
}
.single-page-sidebar .sidebar-item.current .index-span {
  border-color: #2760ad !important;
}

.single-page-sidebar .sidebar-item.current .md-text,
.single-page-sidebar .sidebar-item.current .sm-text {
  color: #2760ad !important;
}

.single-page-sidebar .sidebar-item:not(.completed):not(.current) a:hover {
  border: 1px solid #2760ad !important;
}

@media screen and (max-width: 768px) {
  .single-page-sidebar .sidebar-item-list {
    padding-right: 0;
  }
}
.attachments-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
  margin-top: 25px;
  margin-bottom: 15px;
}
.attachement-btn {
  background-color: #eaf7fe;
  border: 0;
  outline: 0;
  padding: 12px 14px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: var(--transition);
}
.attachement-btn img {
  height: 20px;
  margin-right: 8px;
  flex: none;
}
.attachement-btn:not(:disabled):hover {
  background-color: #d9effb;
}

.content-footer .btn-primary {
  min-width: 120px;
}

.pdf-fullscreen-btn {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  color: #666;
  background-color: transparent;
  border-radius: 5px;
  transition: 0.3s ease;
  text-decoration: none;
}

.pdf-fullscreen-btn:hover {
  background-color: #fafafa;
}

.btn-outline-blue-btn {
  padding: 6px 14px;
  font-size: 12px;
  color: rgba(39, 96, 173, 1);
  font-weight: 500;
  border: 1px solid rgba(39, 96, 173, 1);
}

.btn-outline-blue-btn:hover {
  color: rgba(39, 96, 173, 1);
  border: 1px solid rgba(39, 96, 173, 1);
  background: #fff;
}

@media screen and (max-width: 420px) {
  .single-page-content .content-wrapper .content-footer button {
    font-size: 12px !important;
    padding: 8px;
  }
}

.task-navigate-btn {
  color: black;
  border: 0;
  outline: 0;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  user-select: none;
}

.task-navigate-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.data-row span {
  text-align: left;
  width: 40%;
  display: inline-block;
  word-break: break-word;
}

.data-row strong {
  text-align: left;
  width: 60%;
  display: inline-block;
  word-break: break-all;
}

.tasks-content table {
  width: 100% !important;
}
.tasks-content table th {
  background-color: #f1f1f1 !important;
}
.tasks-content table td,
.tasks-content table th {
  font-size: 16px;
  text-align: left;
  padding: 6px 14px;
  border-width: 1px !important;
  border-color: #222 !important;
}
.tasks-content blockquote {
  border-left: 4px solid #777;
  padding-left: 16px;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  color: #777;
}
.tasks-content img {
  max-width: 100%;
  vertical-align: middle;
}

.trading-download {
  border: 1px solid #2760ad;
  border-radius: 40px;
  color: #2760ad;
  padding: 7px 14px;
  background-color: white;
}
.trading .trading-info {
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
}

.trading > img {
  height: 18px;
  width: 18px;
}

span > svg {
  margin-left: 6px;
}
