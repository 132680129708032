/* BREADCRUMB */
.breadcrumb-element .breadcrumb-item {
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  line-height: normal;
  text-transform: capitalize;
}
.breadcrumb-element .breadcrumb-item a {
  color: #000;
  text-decoration: none;
}
.breadcrumb-element .breadcrumb-item a img {
  height: 14px;
  vertical-align: middle;
}

/* PAGE INSIDE */

.logo-group {
  display: flex;
  align-items: center;
  gap: 25px;
}
.logo-group img {
  height: 100px;
  width: 200px;
  object-fit: contain;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .logo-group img {
    height: 45px;
    width: 90px;
    object-fit: contain;
  }
}

.inner-page-header {
  padding-top: 10px;
  margin-bottom: 24px;
  gap: 14px;
}

.inner-page-breadcrumb {
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .inner-page-header,
  .inner-page-breadcrumb {
    margin-bottom: 50px;
  }
}

.inner-page-header .page-title {
  color: #171717;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0;
  word-break: break-all;
}

@media screen and (min-width: 768px) {
  .layout-scrollable {
    min-height: calc(100dvh - 285px);
  }
}
@media screen and (max-width: 767px) {
  .layout-scrollable {
    min-height: calc(100dvh - 220px);
  }
}

@media screen and (max-width: 575px) {
  .layout-scrollable {
    min-height: calc(100dvh - 195px);
  }
}
