
.activation_code_title {
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #171717 !important;
  min-width: 290px !important;
  line-height: 28px;
}

.activation_code .activation_code_message {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #4d4d4d !important;
}

.activation_code .activation_code_error {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 17px !important;
  max-width: 100% !important;
  text-align: start !important;
  margin-top: .25rem !important;
}

.activation_close_action {
  background: transparent;
  border: none;
  outline: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.activation_form_control {
  height: 48px;
  border-radius: 9px;
}

.btn-activation_code {
  height: 48px !important;
}