.auth-header .header-left-block h3 {
  line-height: normal;
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 500;
}
@media screen and (min-width: 992px) {
  .auth-header .header-left-block h3 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .auth-header .header-left-block h3 {
    display: none !important;
  }
}

.header-info-group {
  display: flex;
  align-items: center;
  gap: 20px;
}
.countdown-timer {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #333333;
  background-color: transparent;
  line-height: normal;
  border-radius: 30px;
  padding: 8px 18px;
  user-select: none;
}

.countdown-timer.timeup-warning {
  -moz-animation: timeupAnimate 1s infinite;
  -webkit-animation: timeupAnimate 1s infinite;
  animation: timeupAnimate 1s infinite;
}

@-moz-keyframes timeupAnimate {
  0% {
    color: #333333;
    background-color: transparent;
  }
  50% {
    color: #ff2424;
    background-color: #ffeeee;
  }
  100% {
    color: #333333;
    background-color: transparent;
  }
}
@-webkit-keyframes timeupAnimate {
  0% {
    color: #333333;
    background-color: transparent;
  }
  50% {
    color: #ff2424;
    background-color: #ffeeee;
  }
  100% {
    color: #333333;
    background-color: transparent;
  }
}
@keyframes timeupAnimate {
  0% {
    color: #333333;
    background-color: transparent;
  }
  50% {
    color: #ff2424;
    background-color: #ffeeee;
  }
  100% {
    color: #333333;
    background-color: transparent;
  }
}

.header-info-group .header-info-item {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.header-info-group .header-info-item img {
  height: 18px;
}
.auth-header .user-info-block .user-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
  line-height: normal;
}

.auth-header .user-info-block .user-reg-number {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.question-page-content-holder-block {
  width: 100%;
}
.question-page-header {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  margin-top: -30px;
  /* box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2); */
  /* z-index: 200; */
}
.question-header-btn-group {
  display: flex;
  align-items: center;
  padding: 15px 0;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.question-header-btn-group:not(.modal-question-sections-list) {
  width: calc(100% - 345px);
}

.question-header-btn-group .question-header-btn {
  background-color: #fff;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #e6e6e6;
  height: 40px;
  padding: 5px 12px;
  margin-left: -1px;
  -webkit-user-select: none;
  user-select: none;
  transition: var(--transition);
  word-wrap: normal;
  white-space: nowrap;
}

.question-header-btn-group
  .question-header-btn:not(.active):not(:disabled):hover {
  background-color: #f2f2f2 !important;
}
.question-header-btn-group .question-header-btn.active {
  background-color: #f2f2f2 !important;
  color: #131314;
  font-weight: 500;
  cursor: default;
  pointer-events: none;
}

.question-header-btn-group .question-header-btn:last-child {
  border-radius: 0 5px 5px 0;
}

.question-header-btn-group .question-header-btn:first-child {
  border-radius: 5px 0 0 5px;
}

.question-page-content-wrapper .question-count-label {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.question-page-content-wrapper .question-content-wrapper {
  padding: 0 10px 20px 0;
  height: calc(100dvh - 192px);
  overflow-y: auto;
}
.question-content-view {
  text-align: left;
}
.question-content-view p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.question-content-view .question-options-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.question-content-view .question-options-list .question-option {
  position: relative;
  text-align: left;
}
.question-content-view
  .question-options-list
  .question-option:not(:last-child) {
  margin-bottom: 18px;
}
.question-content-view .question-options-list label {
  border: 1px solid #cccccc;
  background-color: #fff;
  padding: 8px 16px 8px 46px;
  border-radius: 8px;
  cursor: pointer;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  user-select: none;
  width: 100%;
  max-width: 395px;
  transition: var(--transition);
}
.question-content-view .question-options-list label:hover {
  border-color: #b8c3da !important;
}
.question-content-view .question-options-list input[type="radio"] {
  display: none;
}

.question-content-view .question-options-list .check {
  display: block;
  position: absolute;
  border: 2px solid #cccccc;
  border-radius: 100%;
  pointer-events: none;
  height: 18px;
  width: 18px;
  top: 10px;
  left: 12px;
  z-index: 5;
  transition: border var(--transition);
  -webkit-transition: border var(--transition);
}

.question-content-view .question-options-list .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 10px;
  width: 10px;
  top: 2px;
  left: 2px;
  margin: auto;
  transition: background var(--transition);
  -webkit-transition: background var(--transition);
}

.question-content-view
  .question-options-list
  input[type="radio"]:checked
  ~ .check {
  border: 2px solid var(--primary-button);
}

.question-content-view
  .question-options-list
  input[type="radio"]:checked
  ~ .check::before {
  background: var(--primary-button);
}

.question-content-view
  .question-options-list
  input[type="radio"]:checked
  + label {
  background-color: #e6eaf2 !important;
  border-color: #b8c3da !important;
}

.question-content-view
  .question-options-list
  input[type="radio"]:disabled
  + label {
  pointer-events: none !important;
  opacity: 0.7;
}

.question-content-footer {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-block-btn-group,
.question-content-footer .action-block-right {
  display: flex;
  align-items: center;
}
.action-block-btn-group {
  gap: 12px;
}
.question-content-footer .action-block-right {
  gap: 20px;
  padding-right: 36px;
}
.action-block-btn-group .question-rounded-btn {
  border: 0;
  background-color: #e8eefc;
  color: #00438b;
  padding: 7px 12px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  user-select: none;
  transition: var(--transition);
}
.action-block-btn-group .question-rounded-btn:not(:disabled):hover {
  background-color: #dee7fd;
}
.action-block-btn-group .question-rounded-btn:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}
.question-content-footer .action-block-right .question-nav-footer-btn {
  background-color: transparent;
  border: 0;
  color: #131314;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 7px 16px;
  border-radius: 24px;
  user-select: none;
  transition: var(--transition);
}
.question-content-footer
  .action-block-right
  .question-nav-footer-btn:not(:disabled):hover {
  background-color: #e8eefc;
}
.question-content-footer .action-block-right .question-nav-footer-btn:disabled {
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}
.action-block-btn-group .question-rounded-btn img {
  height: 19px;
}
.question-content-footer .action-block-right .question-nav-footer-btn img {
  height: 12px;
  transform: translateX(0);
  transition: var(--transition);
}
.question-content-footer
  .action-block-right
  .question-nav-footer-btn.question-nav-btn__next:hover
  img {
  transform: translateX(2px);
}
.question-content-footer
  .action-block-right
  .question-nav-footer-btn.question-nav-btn__prev:hover
  img {
  transform: translateX(-2px);
}

/* Task sidebar */

.question-sidebar-block-wrapper {
  width: 340px;
  padding-left: 0;
}

.question-sidebar-holder {
  margin-top: -45px;
  position: relative;
  z-index: 10;
}
.question-sidebar-block {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  border: 1px solid #e6e6e6;
}
.question-sidebar-block .question-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question-sidebar-block .question-sidebar-header h4 {
  color: #131314;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.question-sidebar-block .question-sidebar-header button {
  font-size: 14px;
  font-weight: 500;
  color: #131314;
  text-decoration: underline;
  border: 0;
  background-color: transparent;
  outline: 0;
  user-select: none;
}
.question-sidebar-block .question-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  padding: 12px 16px;
}

.question-sidebar-block .question-sidebar-content {
  overflow-y: auto;
  padding: 18px;
  height: calc(100dvh - 290px);
}

.question-sidebar-block .question-nav-btn-group {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 14px;
  place-items: center;
}
.question-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  outline: 0;
  position: relative;
  border: 0;
  transition: var(--transition);
}
.question-nav-btn-group .question-nav-btn {
  border: 1px solid #e6e6e6;
}
.question-sidebar-block .question-sidebar-legends {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-bubble {
  border: 2px solid #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.question-nav-btn .nav-bubble {
  position: absolute;
  right: -6px;
  bottom: 0px;
}

.question-sidebar-block
  .question-nav-btn-group
  .question-nav-btn:not(.current):not(:disabled):hover {
  border-color: #ccc;
}
.question-nav-btn.current {
  background-color: var(--primary-button) !important;
  color: #fff !important;
}
.question-nav-btn.completed {
  background-color: #02b449;
  color: #fff;
}
.nav-bubble.marked {
  background-color: #f4db41 !important;
  background-image: url("assets/images/icons/flag-icon-black.svg") !important;
  background-size: 6px !important;
}
.nav-bubble.completed {
  background-color: #02b449;
  background-image: url("assets/images/icons/check-icon-white.svg");
  background-size: 8px;
}

.nav-bubble.not-attended {
  background-color: #ededed;
  border-color: #e6e6e6;
}
.nav-bubble.current {
  background-color: var(--primary-button);
}
.question-sidebar-block .question-sidebar-footer {
  padding: 14px 18px;
  border-top: 1px solid #e6e6e6;
}
.question-sidebar-block .question-sidebar-legends {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.question-sidebar-block .question-sidebar-legends .legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;
}
.question-sidebar-legends .legend-item .legend-txt {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.question-sidebar-footer-action .btn.btn-blue {
  width: 100%;
}

/* All questions modal */

.common-modal.modal__all-questions .common-modal-content {
  max-width: 1120px;
  padding: 0;
}

.common-modal.modal__all-questions .common-modal-header {
  background-color: #f2f2f2;
  border-bottom: 1px solid #e6e6e6;
  padding: 12px 16px;
}
.common-modal.modal__all-questions .common-modal-header h3 {
  font-size: 14px;
}
.common-modal.modal__all-questions .modal-content-block {
  padding: 0;
}
.common-modal.modal__all-questions .modal-content-block p {
  max-width: 100%;
}

.modal-questions-list-wrap {
  width: 100%;
  padding: 20px 16px 0;
  max-height: calc(100dvh - 180px);
  overflow-y: auto;
}

@media screen and (min-width: 576px) {
  .modal-questions-list-wrap {
    max-height: calc(100dvh - 212px);
  }
}

.common-modal.modal__all-questions .question-header-btn-group {
  padding: 0 16px 16px;
  border-bottom: 1px solid #e6e6e6;
}

.common-modal.modal__all-questions
  .question-header-btn-group
  .question-header-btn.active,
.question-page-content-mob
  .question-header-btn-group
  .question-header-btn.active {
  background-color: var(--primary-button) !important;
  border-color: var(--primary-button) !important;
  color: #fff;
  font-weight: 500;
  cursor: default;
}

.common-modal.modal__all-questions .modal-questions-sidebar {
  width: 300px;
  background-color: #fbfbfb;
}

.modal-questions-list {
  padding-left: 0;
  margin-bottom: 20px;
}

.modal-questions-list .modal-question-item {
  border: 1px solid #e6e6e6;
  margin-bottom: -1px;
  position: relative;
  list-style-type: none;
  transition: background-color var(--transition);
}
.modal-questions-list .modal-question-item:first-child {
  border-radius: 10px 10px 0 0;
}
.modal-questions-list .modal-question-item:last-child {
  border-radius: 0 0 10px 10px;
}
.modal-questions-list .question-item-content-holder {
  padding: 18px;
  cursor: pointer;
}
.modal-questions-list .modal-question-item:hover {
  background-color: rgba(220, 230, 252, 0.25);
}
.modal-questions-list .modal-question-item .question-nav-btn {
  user-select: none;
  cursor: default;
  pointer-events: none;
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.modal-questions-list .modal-question-toggle,
.modal-questions-list .modal-question-index {
  position: absolute;
  top: 22px;
}
.modal-questions-list .modal-question-index {
  left: 16px;
}
.modal-questions-list .modal-question-toggle {
  right: 16px;
}

.modal-questions-list .question-toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  outline: 0;
  border: 0;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  background-image: url("assets/images/icons/arrow-down-icon-black.svg");
  transform: rotate(0deg);
  transition: var(--transition);
}
.modal-questions-list .question-toggle-btn.toggle__open {
  transform: rotate(180deg);
}
.modal-questions-list .question-toggle-btn.toggle__close {
  transform: rotate(0deg);
}
.modal-questions-list .modal-question-content {
  padding-left: 46px;
  padding-right: 44px;
}

.modal-questions-list .question-options-list {
  margin-top: 20px;
}
.modal-questions-list .modal-question-item .nav-bubble {
  height: 16px;
  width: 16px;
}
.modal-questions-list .modal-question-item .nav-bubble.marked {
  background-size: 5px;
}
.modal-questions-list .modal-question-item .nav-bubble.completed {
  background-size: 6px;
}
.common-modal.modal__all-questions
  .question-sidebar-block
  .question-sidebar-content {
  padding: 16px 5px;
  height: calc(100dvh - 264px);
}
.common-modal.modal__all-questions
  .question-sidebar-block
  .question-nav-btn-group {
  gap: 8px;
  column-gap: normal;
}

.common-modal.modal__all-questions .question-sidebar-block {
  border-radius: 0;
  border: 0;
  border-left: 1px solid #e6e6e6;
}

/* CONFIRM MODAL */

.common-modal.modal__answer-submit-confirm .common-modal-content {
  max-width: 470px;
  padding: 18px 22px;
}
.common-modal.modal__answer-submit-confirm .common-modal-content .modal-icon {
  height: 200px;
  margin-bottom: 10px;
}
.common-modal.modal__answer-submit-confirm .common-modal-content p {
  color: #666666;
  font-size: 18px;
  max-width: 100%;
}

.common-modal.modal__answer-submit-confirm .button-group .btn.btn-primary {
  min-width: 89px;
  border: 1px solid var(--primary-button);
}
.common-modal.modal__answer-submit-confirm .button-group .btn.btn-secondary {
  color: var(--primary-button);
  border: 1px solid var(--primary-button);
  background-color: transparent;
}

/* Mob ui */

.question-page-sub-header {
  padding: 8px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-top: -30px;
}
.question-top-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question-subheader-menu-btn,
.question-footer-block .question-rounded-nav-btn {
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  border: 0;
  outline: 0;
  border-radius: 50%;
}
.question-footer-block .question-rounded-nav-btn:disabled {
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

.question-subheader-menu-btn img {
  height: 24px;
}
.question-top-sub-header .countdown-timer {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #00438b;
}
.question-subheader-submit-btn {
  padding: 8px 16px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: var(--primary-button);
}

.question-footer-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question-footer-block .question-rounded-nav-btn img {
  height: 14px;
}
.question-footer-mob {
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.modal-question-index-sm-device {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@media screen and (min-width: 992px) {
  .modal-question-index-sm-device {
    display: none !important;
  }
}
.modal-question-index-sm-device .question-modal-content {
  position: relative;
  background-color: #fff;
  z-index: 805;
  border-radius: 8px 8px 0 0;
  width: 100%;
  max-width: 360px;
  max-height: calc(100dvh - 100px);
}

.question-modal-close-btn {
  width: 32px;
  height: 32px;
  border: 0;
  background: none;
  outline: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -45px;
}
.question-modal-close-btn img {
  height: 32px;
}

.question-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-bottom: 1px solid #e6e6e6;
}
.question-modal-header h3 {
  margin: 0;
  color: #2c385e;
  font-size: 16px;
  font-weight: 500;
}
.question-modal-header .header-info-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f2f2f2;
}
.question-modal-header .header-info-group {
  gap: 15px;
}
.question-modal-header .header-info-item img {
  height: 16px;
}

.question-sections-group .question-header-btn-group {
  padding: 10px 16px;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  flex-direction: row;
  max-width: 100%;
  white-space: nowrap;
}

.question-sections-group .question-header-btn-group .question-header-btn {
  text-wrap: nowrap;
}

.question-content-block .question-sidebar-block {
  border-radius: 0;
  border: 0;
}
.question-content-block .question-sidebar-header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}
.question-content-block .question-sidebar-content {
  padding: 16px;
  height: calc(100dvh - 352px);
}
.question-content-block .question-sidebar-legends {
  margin-bottom: 0;
}

.question-all-header-mob {
  border-bottom: 1px solid #e6e6e6;
  padding: 14px;
}
.question-all-header-mob h3 {
  font-size: 18px;
  font-weight: 600;
  color: #131314;
  margin-bottom: 0;
}
.question-page-content-mob .question-header-btn-group {
  padding: 10px 0;
}
.question-page-content-mob .question-page-content-holder-mob {
  padding-left: 0;
  padding-right: 0;
}
.question-page-content-mob .modal-question-item {
  border-right: 0;
  border-left: 0;
}

.question-attachment-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: flex-start;
}
.question-attachment-group .question-attachment {
  width: 170px;
  margin: 10px;
  cursor: pointer;
  outline: none;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .question-attachment-group .question-attachment {
    max-width: 25%;
    width: auto;
  }
}

.no-attachment-msg {
  padding: 0 10px;
  display: inline-block;
  font-size: 14px;
  color: #777;
}
.attachment-overlay {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #00438b14;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #fff;
  transition: 0.2s ease;
}
.attachment-overlay svg {
  height: 26px;
  width: 26px;
  vertical-align: middle;
}
.question-attachment-group .question-attachment:hover .attachment-overlay {
  background-color: #00438b47;
}
.question-attachment-group .question-attachment img {
  max-height: 120px;
}
.media-wrapper img {
  max-width: 100%;
  margin: auto;
  display: block;
}

@media screen and (max-width: 575px) {
  .question-page-content-wrapper .question-content-wrapper {
    height: calc(100dvh - 168px);
  }
}

@media screen and (max-width: 991px) {
  .auth-header .countdown-timer,
  .auth-header .header-info-group,
  .question-sidebar-block-wrapper,
  .question-page-header,
  .question-content-footer {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .question-page-sub-header,
  .question-footer-mob {
    display: none !important;
  }
  .question-page-content-holder-block {
    width: calc(100% - 340px);
    padding-right: 0;
  }
  .question-page-content-wrapper .question-content-wrapper {
    padding: 0 20px 30px 0;
    height: calc(100dvh - 222px);
    margin-right: 10px;
  }
}

.common-modal.modal__answer-submit-confirm,
.common-modal.modal__all-questions,
.common-modal.modal__timeout {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.common-modal.modal__answer-submit-confirm {
  max-width: 470px;
}

.common-modal.modal__all-questions {
  max-width: 1120px;
}
.common-modal.modal__timeout {
  max-width: 470px;
}
.common-modal.modal__timeout .common-modal-content .modal-icon {
  height: 200px;
  margin-bottom: 22px;
}
.common-modal.modal__timeout .common-modal-content h3 {
  color: #545454;
  font-size: 22px;
  font-weight: 700;
}
.common-modal.modal__timeout .common-modal-content p {
  color: #545454;
  font-size: 16px;
  font-weight: 400;
}

.question-modal-overlay {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  position: inherit;
  top: 0;
  left: 0;
  z-index: inherit;
}

@media screen and (max-width: 380px) {
  .question-footer-block .action-block-btn-group .question-rounded-btn {
    padding: 7px 8px;
    font-size: 12px;
  }
  .question-footer-block .question-rounded-nav-btn {
    width: 36px;
    height: 36px;
  }
  .question-footer-block .question-rounded-nav-btn img {
    height: 12px !important;
  }
}
