.global-error-wrap {
  max-width: 600px;
  margin: 30px auto;
  border: 1px solid rgb(248, 164, 164);
  padding: 30px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
}

.global-error-wrap > a {
  color: #00438b;
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  display: inline-block;
  outline: none;
  margin-top: 18px;
  text-decoration: underline !important;
}
