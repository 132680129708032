/* ASSESSMENTS */

.assessments-main-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 40px;
}

.assessments-main-list .assessment-list-item {
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  overflow: hidden;
}
.assessments-main-list .assessment-list-item:not(:last-child) {
  margin-bottom: 25px;
}
.assessments-main-list .assessment-info-card {
  padding: 16px;
}

.assessment-list-item.assessment-card-default .assessment-info-card {
  background-color: #e6ebf5;
}
.assessment-list-item.assessment-card-passed .assessment-info-card {
  background-color: #f3fdf5;
}
.assessment-list-item.assessment-card-failed .assessment-info-card {
  background-color: #ffefef;
}
.assessment-list-item.assessment-card-waiting .assessment-info-card {
  background-color: #fffaef;
}
.assessment-list-item.assessment-card-not-attempted .assessment-info-card {
  background-color: #f2f2f2;
}
.assessment-list-item.assessment-card-continue .assessment-info-card {
  background-color: #fbf7ee;
}

.assessment-info-card h2 {
  font-size: 16px;
  font-weight: 500;
  color: #131314;
  margin-bottom: 10px;
}

.assessment-info-card .info-badge-group {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 18px;
}
.assessment-info-card .info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.assessment-info-card .info-item img {
  height: 16px;
  vertical-align: middle;
}
.assessment-info-card .info-item span {
  font-size: 12px;
  font-weight: 400;
}

.assessment-info-card .info-badge {
  padding: 6px 12px;
  display: inline-block;
  border: 1px solid #c59325;
  border-radius: 24px;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .common-modal.modal__assessment .modal-time-block {
    margin-top: 18px;
  }
}

@media screen and (max-width: 991px) {
  .assessment-info-card .info-badge-group {
    margin-bottom: 10px;
  }
  .assessment-info-card .info-badge {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .assessment-info-card .basic-info-right-block .btn {
    width: 100%;
    padding: 8px 16px;
  }
  .assessment-attempt-list .attempt-list-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .assessment-attempt-list .attempt-list-column:last-child {
    margin-bottom: 0;
  }
  .assessment-attempt-list .attempt-list-column > span {
    display: block !important;
    text-align: left;
    width: 50%;
  }
  .attempt-list-item .attempt-list-column.column-wide .date-info-block {
    width: 50%;
  }
  .assessment-attempt-list {
    padding: 0 16px 5px;
  }
}

@media screen and (min-width: 992px) {
  .assessments-main-list .assessment-info-card {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .assessment-info-card h2 {
    font-size: 24px;
  }
  .assessment-info-card .info-item span {
    font-size: 14px;
    font-weight: 500;
  }
  .assessment-info-card .basic-info-row {
    display: flex;
    gap: 18px;
    align-items: center;
  }

  .assessment-attempt-list {
    padding: 0 24px 20px;
  }

  .assessment-attempt-list .attempt-list-item {
    display: flex;
    gap: 12px;
  }
  .attempt-list-item .attempt-list-column:not(.column-wide) {
    flex: 0 0 8%;
    width: 8%;
  }
  .attempt-list-item .attempt-list-column.column-wide {
    width: calc(100% - 32%);
  }
  .attempt-list-item .attempt-list-column.column-wide .date-info-block {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.assessment-status-row {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.assessment-list-item.assessment-card-passed .assessment-status-row {
  background-color: #bbebbb;
}
.assessment-list-item.assessment-card-failed .assessment-status-row {
  background-color: #ffdede;
}
.assessment-list-item.assessment-card-waiting .assessment-status-row {
  background-color: #ffe8b7;
}
.assessment-list-item.assessment-card-not-attempted .assessment-status-row {
  background-color: #d8d7d4;
}

.assessment-status-row .status-item {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: auto;
}
.assessment-status-row .status-item img {
  height: 20px;
}

.assessment-attempt-list .attempt-list-item {
  width: 100%;
  padding: 16px 0;
}
.assessment-attempt-list .attempt-list-item:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.attempt-list-item .attempt-list-column .colum-label {
  display: block;
}
.attempt-list-item .attempt-list-column .colum-label {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 8px;
}
.attempt-list-item .colum-txt-group {
  display: flex;
  align-items: center;
  gap: 4px;
}
.attempt-list-item .attempt-list-column .colum-txt {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}
/* ATTEMPT STATUS COLORS */

.attempt-list-item .attempt-list-column .colum-txt.green-txt {
  color: #00ab65 !important;
}
.attempt-list-item .attempt-list-column .colum-txt.red-txt {
  color: #f04438 !important;
}
.attempt-list-item .attempt-list-column .colum-txt.yellow-txt {
  color: #dcae34 !important;
}
.attempt-list-item .attempt-list-column .colum-txt.teal-txt {
  color: #14bb97 !important;
}
.attempt-list-item .attempt-list-column .colum-txt.blue-txt {
  color: #2e8ad6 !important;
}
.attempt-list-item .attempt-list-column .colum-txt.orange-txt {
  color: #e47023 !important;
}
.attempt-list-item .attempt-list-column .colum-txt.grey-txt {
  color: #55595f !important;
}

.attempt-list-item .colum-txt-group img {
  height: 12px;
}

.assessment-list-item .schedule-date-box {
  text-align: center;
}
.assessment-list-item .date-box-label {
  color: #272d3d;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  display: inline-block;
  line-height: normal;
}

.assessment-list-item .date-group-box {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.assessment-list-item .date-time-box {
  background-color: #fff;
  border: 1px solid #e1e3ee;
  height: 62px;
  border-radius: 8px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.assessment-list-item .date-time-box span {
  display: block;
  color: #171717;
  line-height: normal;
}
.assessment-list-item .date-time-box .span-day {
  font-size: 24px;
  font-weight: 600;
}
.assessment-list-item .date-time-box .span-month-year {
  font-size: 12px;
  font-weight: 500;
}
.assessment-list-item .date-time-box .span-time {
  font-size: 12px;
  font-weight: 400;
}
.assessment-list-item .date-time-box .span-time-sm-txt {
  font-size: 7px;
  font-weight: 500;
  color: #5f6368 !important;
}

.assessment-list-item .assessment-sm-loader {
  height: 48px;
  width: 48px;
}

/* ASSESSMENT SCHEDULE MODAL */
.common-modal.modal__assessment {
  max-width: 800px;
  padding: 0;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.common-modal.modal__assessment .block-title {
  font-size: 16px;
  color: #131314;
  font-weight: 400;
  margin-bottom: 10px;
  display: block;
}
.assessment-time-btns {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.assessment-time-btns .time-btn {
  border: 1px solid #e6e6e6;
  background-color: white;
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 8px 12px;
  align-items: center;
  border-radius: 5px;
  outline: 0;
  width: calc(50% - 8px);
  line-height: normal;
  user-select: none;
  transition: var(--transition);
}
.assessment-time-btns .time-btn:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
.assessment-time-btns .time-btn:disabled svg,
.assessment-time-btns .time-btn:disabled span {
  opacity: 0.8;
}
.assessment-time-btns .time-btn svg {
  height: 18px;
  width: 18px;
}
.assessment-time-btns .time-btn:not(:disabled):not(.active):hover {
  background-color: var(--primary-button-hover-light);
  border-color: #cad9ff;
}
.assessment-time-btns .time-btn.active {
  border-color: #cad9ff !important;
  cursor: default;
}
.assessment-time-btns .time-btn.active svg path,
.assessment-time-btns .time-btn:not(:disabled):not(.active):hover svg path {
  fill: var(--primary-button);
}
.assessment-time-btns .time-btn.active span,
.assessment-time-btns .time-btn:not(:disabled):not(.active):hover span {
  color: var(--primary-button);
}

.assessment-time-btns .time-btn span {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

/* ASSESSMENT INFORMATION MODAL */

.common-modal.modal__assessment_schedule,
.common-modal.modal__verification_code {
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}
.common-modal.modal__assessment_schedule .common-modal-content {
  padding: 20px 0 !important;
}
.common-modal.modal__assessment_schedule .common-modal-content .modal-icon {
  height: 200px;
  margin-bottom: 12px;
}
.common-modal.modal__assessment_schedule .common-modal-content .modal-sm-txt {
  max-width: 100%;
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}
.common-modal.modal__assessment_schedule .common-modal-content .modal-bold-txt {
  max-width: 100%;
  color: #131314;
  font-size: 16px;
  font-weight: 700;
}

.common-modal.modal__assessment_schedule .common-modal-content .modal-action {
  margin-top: 24px;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.common-modal .modal-calendar-block .rdt {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.common-modal .modal-calendar-block .rdtPicker .dow,
.common-modal .modal-calendar-block .rdtDay {
  width: calc(300px / 7);
}
.common-modal .modal-calendar-block .rdtPicker {
  border-color: #ebebeb !important;
  border-radius: 6px;
}

.common-modal .modal-calendar-block .rdtDay {
  height: 42px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
}
.common-modal .modal-calendar-block .rdtDay.rdtToday {
  background-color: #ffefd6;
}
.common-modal .modal-calendar-block .rdtDay.rdtActive {
  background-color: #324f96 !important;
}

.common-modal .modal-calendar-block .rdtActive.rdtToday:before {
  display: none;
}

.common-modal.modal__instructions,
.common-modal.modal__helps {
  max-width: 660px;
  padding: 0;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.common-modal.modal__instructions .block-title {
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 500;
  display: block;
  line-height: 24px;
  margin-bottom: 28px;
}
.instruction-points-group {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.instruction-points-group .point-item {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 25%;
}
.instruction-points-group .point-item .point-icon {
  width: 30px;
  height: 30px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.instruction-points-group .point-item .point-icon img {
  height: 16px;
}
.instruction-points-group .point-item .point-label {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.instructions-content-inner-block h4 {
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 14px;
}
.instructions-content-inner-block ul {
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 16px 24px;
}
.instructions-content-inner-block li {
  padding-left: 6px !important;
  font-size: 16px;
  font-weight: 400;
  color: #4d4d4d;
  margin-bottom: 4px !important;
  margin-left: 8px !important;
}

/* Network badge */

.network-status-badge {
  position: fixed;
  top: 10px;
  left: 50%;
  z-index: 250;
  padding: 2px 10px 2px 30px;
  transform: translateX(-50%);
  background-color: #ffd3d3;
  border-radius: 40px;
  font-size: 14px;
  color: #f00;
  font-weight: 600;
}

.network-status-badge .badge-dot {
  background-color: transparent;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  left: 10px;
  top: 7px;
  z-index: 1;
}
.network-status-badge .badge-dot::after,
.network-status-badge .badge-dot::before {
  content: "";
  border-radius: 50%;
  display: block;
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
}
.network-status-badge .badge-dot::before {
  width: 10px;
  height: 10px;
  background-color: #f00;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.network-status-badge .badge-dot::after {
  width: 25px;
  height: 25px;
  background-color: #ff7373;
  z-index: 0;
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
  animation: badgeDot 2s infinite;
}

@keyframes badgeDot {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

.network-info-modal .network-modal-icon {
  height: 100px;
  width: 100px;
  margin: 0 auto 10px;
  display: block;
}

.network-info-modal .network-modal-icon svg {
  height: 100px;
  width: 100px;
}
.network-info-modal .common-modal-content h3 {
  color: #000000 !important;
  font-size: 26px !important;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
}
.common-modal.network-info-modal {
  max-width: 360px !important;
}

.network-info-modal .network-modal-txt {
  font-size: 14px;
}
