.controlled-textarea {
  padding-bottom: 30px !important;
}
.controlled-textarea .char-length,
.controlled-textarea .invalid-feedback {
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  z-index: 4;
}

.controlled-textarea .invalid-feedback {
  left: 0;
  bottom: 0;
}
.controlled-textarea .char-length {
  right: 0;
  bottom: 8px;
  font-size: 12px;
  color: #444;
}
.sm-label-txt {
  font-size: 13px;
  color: #444;
  margin-left: 8px;
}
