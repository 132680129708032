/* ASSESSMENT RESULT PAGE */
section.assessment-result-page-wrapper {
  margin-top: -30px;
}
.assessment-result-page-banner {
  padding: 30px 0;
  text-align: center;
  position: relative;
}
.assessment-result-page-banner.assessment__passed {
  background-color: #e7faea;
}
.assessment-result-page-banner.assessment__failed {
  background-color: #f5e6e5;
}
.assessment-status-block .status-icon {
  height: 74px;
  margin-bottom: 20px;
}
.assessment-status-block h3.message-txt {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
}
.assessment-result-page-banner.assessment__passed h3.message-txt {
  color: #279628 !important;
}
.assessment-result-page-banner.assessment__failed h3.message-txt {
  color: #f04438 !important;
}
.assessment-status-block h4.sub-txt {
  font-size: 16px;
  font-weight: 500;
  color: #131314;
}
.assessment-status-block {
  position: relative;
  z-index: 10;
}
.assessment-status-block .sm-card-group {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.sm-card-group .sm-card-item {
  border: 1px solid #e1e3ee;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.sm-card-group .sm-card-item img {
  height: 24px;
}
@media screen and (max-width: 600px) {
  .assessment-status-block .sm-card-group {
    gap: 8px;
  }
  .sm-card-group .sm-card-item {
    padding: 8px;
    gap: 4px;
  }

  .sm-card-group .sm-card-item img {
    height: 20px;
  }
}
.sm-card-group .sm-card-item span {
  color: #171717;
  font-size: 12px;
  font-weight: 500;
}
.assessment-result-board {
  text-align: center;
  margin-top: 40px;
  max-width: 385px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.assessment-result-board p {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
}
.assessment-result-board .info-blocks {
  padding-top: 10px;
}

.assessment-result-board .info-blocks .info-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #e6e6e6;
  padding: 14px 30px;
  border-radius: 8px;
}
.assessment-result-board .info-blocks .info-row:not(:last-child) {
  margin-bottom: 10px;
}
.assessment-result-board .info-blocks .info-row .info-label {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 400;
}
.assessment-result-board .info-blocks .info-row .info-value {
  color: #131314;
  font-size: 18px;
  font-weight: 500;
}
.assessment-result-board .action-block {
  margin-top: 20px;
}

.publish-soon-wrap img {
  height: 160px;
  margin-bottom: 20px;
}

.publish-soon-wrap p {
  margin-bottom: 30px;
}
