.empty-msg-icon {
  width: 200px;
  height: 200px;
  background-color: #f5f5f5;
}
.empty-msg-icon > img {
  width: 72px;
  height: 72px;
}
.empty-msg-title {
  font-size: 24px;
  color: #666666;
}
.empty-msg-description {
  font-size: 16px;
  color: #666666;
  max-width: 400px;
}
.empty-msg-action {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
}
