.notification-slider {
  background-color: #f5e6bf;
  width: 100%;
  padding: 10px 0;
}

.notification-slider-group {
  gap: 16px;
}

.notification-card {
  cursor: pointer;
}

.notification-slider .notification-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 10px 10px 10px 56px;
  background-image: url("../../../assets/images/icons/speaker-filled-brown.svg");
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  background-size: 32px;
}

.notification-slider .notification-card-header {
  margin-bottom: 4px;
}
.notification-slider .notification-card-header h4 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.notification-slider .notification-card-header .timestamp {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
}

.notification-slider .notification-content p {
  color: #242225;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  line-height: 16px;
  word-break: break-all;
}
.notification-slider-action {
  gap: 15px;
  max-width: 165px;
}
.notification-slider-action .btn.btn-primary {
  background-color: #2760ad;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  outline: 0;
  border-radius: 10px;
}
.notification-slider-action .notifiction-close {
  background-image: url("../../../assets/images/icons/close-icon-rounded.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 26px;
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.notification-sm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 199;
}

.notification-modal-content {
  width: 90%;
  margin: auto;
  background-color: #f5e6bf;
  border-radius: 20px;
  padding: 54px 24px 50px;
  position: relative;
}

.modal-close-span {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/icons/close-icon-white.svg");
  border-radius: 50%;
  cursor: pointer;
}

.notification-slide {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px 18px;
  text-align: center;
}

.notification-slide img {
  height: 32px;
  display: block;
  margin: 0 auto 12px;
}
.notification-slide h4 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.notification-slide .timestamp {
  font-size: 12px;
  font-weight: 400;
  color: #4d4d4d;
  display: inline-block;
  margin-bottom: 8px;
}
.notification-slide p {
  color: #272d3d;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
@media screen and (min-width: 768px) {
  .main-page-wrapper {
    padding-top: 30px;
  }
}
.slick-slider {
  background-color: #fff;
  border-radius: 12px;
}
